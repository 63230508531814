<!-- eslint-disable -->
<template>
  <div id="wrap" style="position: absolute;display:block;width: 100%; height: -webkit-fill-available;">
    <div class="container" style="height: 100%;">
      <div class="gis" style="height: 100%;">
        <ul class="map-layer"></ul>
      
        <div class="energy-ctx-menu btn_3dview">
          <ul>
            <li data-cb="bld3d" value="">3D건물</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
            <li data-cb="bldBepaReportPopup" class="menu_bepaReportView">현재 건물 BEPA리포트</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn_energyView">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldEnergyDetailPopup">현재 건물 에너지소비량</li>
            <li data-cb="bldBepaReportPopup" class="menu_bepaReportView">현재 건물 BEPA리포트</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn_bldView">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldBepaReportPopup" class="menu_bepaReportView">현재 건물 BEPA리포트</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn-add1">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
            <li data-cb="bldBepaReportPopup" class="menu_bepaReportView">현재 건물 BEPA리포트</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn-add2">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldEnergyDetailPopup">현재 건물 에너지소비량</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
            <li data-cb="bldBepaReportPopup" class="menu_bepaReportView">현재 건물 BEPA리포트</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn_otherView">
          <ul>
            <li data-cb="bldSunLightSummerView">여름 음영지표 보기</li>
            <li data-cb="bldSunLightAnnualView">연간 음영지표 보기</li>
            <li data-cb="bldSunLightWinterView">겨울 음영지표 보기</li>
            <li data-cb="bldSunLightHide">음영지표 끄기</li>
          </ul>
        </div>
        <div id="map" class="gis-map" style="height: 100%;">

          <!--배경건물선택-->
          <div>
            <div class="buldLayerListBtn" @click="buldLayerBtnClk">
              <img class="icon" src="/gis/map/common/images/gis/icon_btn_building.png" alt="iconBuilding">
            </div>
            <div v-show="buldLayerFlag" class="buldLayerList">
              <div class="buldLayer">
                <div class="buldLayerItemWrap" @click="changeBuldLayerVal">
                  <input type="hidden" id="buldLayers" value="TL_SPBD_BULD">
                  <div class="buldLayersItem" id="TL_SPBD_BULD" style="background:#4441D4;"><span class="buldLayerTitle" style="color:white;">행정안전부<br>건물정보</span><span class="buldLayerExp" style="color:white;">도로명주소<br>시스템</span></div>
                  <div class="buldLayersItem" id="AL_D010"><span class="buldLayerTitle">국토부<br>GIS건물정보</span><span class="buldLayerExp">국가공간정보포털<br>오픈API</span></div>
                  <div class="buldLayersItem" id="F_FAC_BULD"><span class="buldLayerTitle">국토부<br>건물통합정보마스터</span><span class="buldLayerExp">국가공간정보포털<br>오픈마켓</span></div>
                </div>
              </div>
            </div>
          </div>
          
          <!--층별보기-->
          <div class="btn-area" style="top:394px !important; right:36px !important;">
            <div class="group" style="margin-bottom: 2px;border-radius: 0.28571429rem;border: 0px;">
              <button type="button" id="selectArea" class="btn-tooltip">
               층별
              </button>
              <button type="button" id="btn-survey" class="btn-tooltip" style="display:none">
               설문
              </button>
            </div>
          </div>

          <!--슬라이드바-->
          <div class="map-level"></div>

        </div>
        <div class="btn-floor">

        </div>
        <button id="btn-floor-close">X</button>
        <div id="select-container">
          <div class="select-box" aria-haspopup="true">
            <button id="btn-dropdown" class="btn-dropdown">만족도 조사</button>
            <nav id="nav-dropdown" class="nav-dropdown">
              <ul>
                <li data-value="1-1.">1-1. 업무공간에서 개별업무 및 사품보관 등에 사용되는 공간의 양에 대해서 얼마나 만족하십니까?</li>
                <li data-value="1-2.">1-2. 업무공간의 시각적인 프라이버시에 대해서 얼마나 만족하십니까?</li>
                <li data-value="1-3.">1-3. 업무공간에서 직장동료들과의 상호소통의 용이한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="2-1.">2-1. 업무공간의 전반적인 온도에 얼마나 만족하십니까?</li>
                <li data-value="2-2.">2-2. 업무공간의 전반적인 습도에 얼마나 만족하십니까?</li>
                <li data-value="3-1.">3-1. 업무공간의 실내공기 신선도가 쾌적한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="3-2.">3-2. 업무공간의 실내공기 냄새가 쾌적한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-1.">4-1. 업무공간의 인공조명의 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-2.">4-2. 업무공간의 자연채광의 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-3.">4-3. 업무공간의 조명의 시각적인 편안감(눈부심, 반사, 대조도 등)에 대해서 얼마나 만족하십니까?</li>
                <li data-value="5-1.">5-1. 업무공간의 소음도(dB) 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="5-2.">5-2. 업무공간 내 대화의 프라이버시 보장 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="6-1." class="on">6-1. 모든 것을 고려하여 개인 업무공간에 얼마나 만족하십니까?</li>
                <li data-value="6-2.">6-2. 이 건물의 환경 조건(예 : 열, 공기질, 조명, 소음, 사무실 공간 배치)에 따라 업무능력이 어느정도 향상 또는 저하되었는지 추정해주십시오.</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
<!-- 
      <div id="loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> -->
<!-- 
      <div
          id="modal_sel"
          class="popLayer draggable"
          style="width:270px; height: fit-content;"
      >
        <dl class="popCntType">
          <dt>
            선택영역 건물정보
          </dt>
          <dd
              class="popCnts"
              style="padding : 20px 20px 20px 20px !important;"
          ></dd>
        </dl>
        <a
            href="#"
            class="btnClosePop"
            onclick="gis.common.hideModal({popID : 'modal_sel', find : '.popCnts'});return false;"
        ><img
            src="/gis/map/common/images/btn_close_pop.png"
            alt="팝업 닫기"
        /></a>
      </div> -->

      <!-- <div
          id="modal_all"
          class="popLayer draggable"
          style="width:270px; height: fit-content;"
      >
        <dl class="popCntType">
          <dt>
            전체영역 장비개수
          </dt>
          <dd
              class="popCnts"
              style="padding : 20px 20px 20px 20px !important;"
          ></dd>
        </dl>
        <a
            href="#"
            class="btnClosePop"
            onclick="gis.common.hideModal({popID : 'modal_all', find : '.popCnts', imgID : '#sel_all', imgFile : './common/images/gis/icon_all.png'});return false;"
        ><img
            src="/gis/map/common/images/btn_close_pop.png"
            alt="팝업 닫기"
        /></a>
      </div> -->
    </div> <!-- end of   <div class="container" style="height: 100%;"-->
     <!--  건물 정보 : S -->
     <div>
      <b-modal
        v-model="modalBuildInfo"
        centered
        ok-only
        ok-title="확인"
        size="xl"
				hide-footer
        :title="`건물 에너지 광역검진정보 (소재지: ${buildTotalInfo.bldAddr} / ${buildTotalInfo.bldNm}) `"
      >
        <div class="gradeDiv">
          <div class="left-div"></div>
          <div class="right-div" style="float:right;">
            <span v-if="this.mapngGrade !== ''">
              <b-badge style="font-size: 15px;" variant="warning">
                <span>{{ mapngGrade }}등급</span>
              </b-badge>
              <span style="padding: 5px;">{{ layerKorName }}</span>
            </span>
            <span v-else>
              <img alt="loading" src="/gis/map/gis/images/common/loading.gif" />
            </span>

            <span class="menuHelp" @mouseout="mout" @mouseover="mover">
              <img src="@/assets/images/logo/help.png" />
            </span>
            <span v-show="helpBoxShow" class="menuHelpBox">
<!--              <img src="/gis/map/gis/images/common/bld_grade.png" />-->
              <b>{{ mapngGrade }}등급</b> {{mapngGradeDesc}}
            </span>
          </div>
        </div>

        <build-total-main :mgm-bld-pk="mgmBldPk" />
        <!--        <b-tabs class="modal-style" content-class="mt-2" justified>
          <b-tab active title="건축물 대장">
            <build-basic-info-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
          <b-tab title="층별 개요">
            <build-floor-overview-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
          <b-tab title="인허가 리스트">
            <build-license-list-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
        </b-tabs>-->
      </b-modal>
    </div>
    <!--  건물 정보 : E -->

    <!-- 동/시군구 통계팝업 : S -->
    <!-- <div>
      <b-modal
          v-model="modalMapAreaInfo"
          size="lg"
          centered
          :title="areaTitle"
          ok-title="확인"
          ok-only
      >
        <map-gis-area-table-component
            :map-type="mapType"
            :sigungu-id="sigunguId"
            :dong-id="dongId"
        />
      </b-modal>
    </div>
    <div>
      <b-modal
          v-model="modalMapAreaInfo"
          size="lg"
          centered
          :title="areaTitle"
          ok-title="확인"
          ok-only
      >
        <map-gis-area-table-component
            :map-type="mapType"
            :sigungu-id="sigunguId"
            :dong-id="dongId"
        />
      </b-modal>
    </div> -->
    <!-- 동/시군구 통계팝업 : E -->
    <!--    에너지 사용랑-->
    <div>
      <b-modal
          v-model="modalEnergyInfo"
          size="lg"
          centered
          title="▶ 건물별 에너지소비량"
          ok-title="확인"
          ok-only
      >
        <b-tabs
            content-class="mt-2"
            class="modal-style"
            justified
        >
          <b-tab
              title="에너지 소비량"
              active
          >
            <energy-use-info-component
                :mgm-bld-pk="mgmBldPk"
            />
          </b-tab>
          <b-tab
              title="계량기 정보"
          >
            <energy-meter-info-component
                :mgm-bld-pk="mgmBldPk"
            />
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
    <!-- 슬라이드 저장
    <div v-if="this.buildClickTempInfo">
      <map-gis-temp-build-info-component
          :mgm-bld-pk="mgmBldPk"
      />
    </div>-->
    <!-- 올가미 -->
    <!-- <div>
      <b-modal
          v-model="modalLassoInfo"
          size="lg"
          centered
          title="▶ 올가미 선택 팝업"
          ok-title="확인"
          ok-only
      >
        <build-lasso-info-component
            :mgm-bld-pk-lasso="mgmBldPkLasso"
        />
      </b-modal>
    </div> -->
    <!-- PDF modal -->
    <div>
      <b-modal v-model="modalPdfBEPA" centered ok-only ok-title="확인" size="lg" title="▶ BEPA 리포트">
        <pdf-bepa-component :mgm-bld-pk="mgmBldPk"></pdf-bepa-component>
      </b-modal>
    </div>

  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import * as THREE from 'three';

import { BBadge, BModal, BTab, BTabs, VBModal, VBToggle, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BuildFloorOverviewComponent from "@/views/building/buildBaiscInfo/BuildFloorOverviewComponent";
import BuildBasicInfoComponent from "@/views/building/buildBaiscInfo/BuildBasicInfoComponent";
import BuildLicenseListComponent from "@/views/building/buildBaiscInfo/BuildLicenseListComponent";
import MapGisAreaTableComponent from "@/views/building/MapGisAreaTableComponent";

import EnergyUseInfoComponent from "@/views/building/EnergyUse/EnergyUseInfoComponent";
import EnergyMeterInfoComponent from "@/views/building/EnergyUse/EnergyMeterInfoComponent";
import MapGisTempBuildInfoComponent from "@/views/building/MapGisTempBuildInfoComponent";
import BuildLassoInfoComponent from "@/views/building/buildBaiscInfo/BuildLassoInfoComponent";
import { mapGetters } from "vuex";
import BuildTotalMain from '@/views/building/buildTotalInfo/buildTotalMain.vue';
import code from "../../../public/gis/map/gis/js/common/code";
import { PreventDragClick } from "../../../public/gis/map/gis/js/common/PreventDragClick";
import * as threeLayer from "../../../public/gis/map/gis/js/all4/threeLayer";
import all4LayerControls from "../../../public/gis/map/gis/js/all4/layerControls";
import bim from "../../../public/gis/map/gis/js/all4/3dModel";

//2023신규UI
//import MapGisTempBuildInfoComponentNew from '@/views/building/MapGisTempBuildInfoComponentNew';
import PdfBepaComponent from '~/building/pdfBepaComponent.vue';

window.mapboxgl = mapboxgl;

export default {
  components: {

	BuildTotalMain,
  PdfBepaComponent,
 //   MapGisTempBuildInfoComponentNew,
  BBadge,

    BuildLassoInfoComponent,
    MapGisTempBuildInfoComponent,
    MapGisAreaTableComponent,

    BModal,

    BuildLicenseListComponent,
    BuildFloorOverviewComponent,
    BuildBasicInfoComponent,
    BTabs,
    BTab,
    EnergyMeterInfoComponent,
    EnergyUseInfoComponent,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      // 전역변수 클릭 여부
      buildClickTempInfo: false,

      modalBuildTotal: false, // 건물 정보 토탈
      modalPdfBEPA: false, // PDF View

      modalBuildInfo: false,
      modalMapAreaInfo: false,
      modalEnergyInfo: false,
      modalLassoInfo: false,

      mgmBldPk: null, // 건물 PK
      mgmBldPkLasso: null, // 올가미로 묶은 건물 PK 배열
      areaTitle: null, // modal의 타이틀값
      mapType: null, // 시군구 or 읍면동 구분값(sigungu, dong)
      sigunguId: null, // 시군구 PK
      dongId: null, // 동 PK
      // 건물 정보 임시 저장 배열
      mgmBldPkArr: [],
      // 건물명, 건물 주소
      buildTotalInfo: {},
      bldInfoData: {},
      helpBoxShow: false,
      mapngGrade: '',
      mapngGradeDesc: '',
      commonEnergy: {},
      purpsArr: [],
      kictModelName: "",
      statusData: { engyCd: "2017", year: "11", clsfCd: "eb" },
      preData: "tb_engy_2017_11_eb",
      
			buldLayerFlag: false,//배경건물선택div
    }
  },
  computed: {
    ...mapGetters({
      mainSelected: "main/getSelectedCodeData",
    }),
  },
  mounted() {
    mapboxgl.accessToken = "pk.eyJ1IjoibGFyZ2VvbmUiLCJhIjoiY2tyanlmZTZ3MTl1czJucGs4NmYzMndwOSJ9.59kffzfEkzqJLHWT5WgrUg";
    let mapOriginCenter = [126.73858609597376, 37.669643134581285];
    let mapOriginZoom = 17.5;
    let bldInfoData;
    let layerMgm;
    let preventDragClick;

    if (location.search) {
      const result = get_query();
      if (!!result.lng && !!result.lat) {
//        if(document.referrer.includes("http://checkup.building-energy.org:9090/")) {
//					result.zoom = parseInt(result.zoom) + 1;
//				}
        if (!result.zoom) {
          result.zoom = 17.5;
        } else {
        	result.zoom = parseInt(result.zoom) + 1;
        }
        mapOriginCenter = [result.lng, result.lat];
        mapOriginZoom = result.zoom;
      }
    }

    /**
       * mapgoxgl.Map 초기 셋팅
       * */
    map = new mapboxgl.Map({
      container: "map",
      // style: '',
      // style: "mapbox://styles/mapbox/light-v10?optimize=true",
      // style: 'mapbox://styles/mapbox/satellite-v9?optimize=true',
       style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
      // style: 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
      // style: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true',
      center: mapOriginCenter,
      zoom: mapOriginZoom,
      bearing: 0,
      pitch: 60,
      attributionControl: false,
      doubleClickZoom: false,
      antialias: true,
      maxTileCacheSize: 2048,
      maxZoom: 22.3,
      minZoom: 6,
      //maxBounds: [118.47210977735858, 30.909944590789138, 136.19768873771102,41.57339747769777],
      maxBounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
      boxZoom: false,
      optimizeForTerrain: false,
      testMode: true,
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');

    function get_query() {
      const url = document.location.href;
      const qs = url.substring(url.indexOf("?") + 1).split("&");
      for (var i = 0, result = {}; i < qs.length; i++) {
        qs[i] = qs[i].split("=");
        result[qs[i][0]] = decodeURIComponent(qs[i][1]);
      }
      return result;
    }

      /**
       * 맵이 로드 될때
       * */
  map.on("load", () => {
    $(".map-level").text(Math.floor(map.getZoom()));
    all4LayerControls.addRaster();
    map.addLayer(bim["3d-model"]);
    all4LayerControls.init();

    map.setLayoutProperty("3d-model", "visibility", "visible");
    preventDragClick = new PreventDragClick(map.getCanvas());
  });

      /**
       * 맵이 움직일때
       * */
  map.on("moveend", (e) => {
    let renewURL = location.href;

    renewURL = renewURL.replace(/\?+.+/gi, "");
    renewURL += `?lng=${map.getCenter().lng}`;
    renewURL += `&lat=${map.getCenter().lat}`;
    renewURL += `&zoom=${map.getZoom()}`;

    history.pushState(null, null, renewURL);
  });

      /**
       * 맵의 zoom 레벨이 다를때
       * */
  map.on('zoom', (e) => {
   $(".map-level").text(Math.floor(map.getZoom()));
  });

      /**
       * 건물에 오른쪽 버튼을 누를때
       * */
  map.on("contextmenu", (e) => {
    $(".btn_3dview").hide();
    $(".btn_energyView").hide();
    $(".btn_bldView").hide();
    $(".btn-add1").hide();
    $(".btn-add2").hide();
    $(".btn_otherView").hide();

    if (preventDragClick.mouseMoved) return;
      bim["3d-model"].raycast(e.point, true);
      const firstBld = map.queryRenderedFeatures(e.point).filter((e) => e.source == all4LayerControls.getCurrentBackgroundBuldLayerName());
      // const firstBld = map.queryRenderedFeatures(e.point).filter((e) => e.source == "vw_tb_engy_bld_yy_tl_spbd_buld");

      if (Array.isArray(firstBld) && firstBld.length !== 0) {
        layerMgm = firstBld[0].properties.mgm_bld_pk;
        bldInfoData = { mgmBldPk: firstBld[0].properties.mgm_bld_pk,
                        mapngGrade: firstBld[0].properties.mapng_grade,
                        layerKorName: '건물별연간에너지사용량(도로명주소시스템)',
                        layerId: 'TL_SPBD_BULD', //2d지도쪽 함수 호출 위한 파라미터
                      // geometry: firstBld[0].properties.geometry
                      };
        if (map.queryRenderedFeatures(e.point)[0].properties.bd_mgt_sn === '1168010600110240003000001') {
          $(".btn-add2").show();
          $(".btn-add2").css({ left: e.point.x + 10, top: e.point.y });
        } else {
          $(".btn_energyView").show();
          $(".btn_energyView").css({ left: e.point.x + 10, top: e.point.y });
        }
      } else {
        const secondBld = map.queryRenderedFeatures(e.point).filter((e) => e.source == "tl_spbd_buld");
        if (Array.isArray(secondBld) && secondBld.length !== 0) {
          layerMgm = secondBld[0].properties.mgm_bld_pk;
          bldInfoData = { mgmBldPk: secondBld[0].properties.mgm_bld_pk,
                          mapngGrade: secondBld[0].properties.mapng_grade,
                          layerKorName: '건물별연간에너지사용량(도로명주소시스템)',
                          layerId: 'TL_SPBD_BULD', //2d지도쪽 함수 호출 위한 파라미터
                        //  geometry: secondBld[0].properties.geometry
                        };
          if (map.queryRenderedFeatures(e.point)[0].properties.bd_mgt_sn === '1144010500100700002003775') {
            $(".btn-add1").show();
            $(".btn-add1").css({ left: e.point.x + 10, top: e.point.y });
          } else {
            $(".btn_bldView").show();
            $(".btn_bldView").css({ left: e.point.x + 10, top: e.point.y });
          }
        }else if( $(".btn_3dview").is(':visible') !=true ){ //3d건물보기메뉴가 없으면 바닥 찍은거임
          $(".btn_otherView").show();
          $(".btn_otherView").css({ left: e.point.x + 10, top: e.point.y });
        }
        
      }

      const bepaBld = map.queryRenderedFeatures(e.point).filter((e) => e.source == "BEPA_diagBldSeoul_TL_SPBD_BULD_geojson");
      if (Array.isArray(bepaBld) && bepaBld.length !== 0) {
        $(".menu_bepaReportView").show();
      }else{
        $(".menu_bepaReportView").hide();
      }

      // 

    window.event.returnValue = false;
  });

      /**
      * 맵에 왼쪽 마우스를 클릭할때
      * */
  map.on("mousedown", (e) => {
    $(".btn_3dview").hide();
    $(".btn_energyView").hide();
    $(".btn_otherView").hide();
    $(".btn_bldView").hide();
    $(".btn-add1").hide();
    $(".btn-add2").hide();
  });
 
  /**
   * 배경건물 선택할때
   */
  $("#buldLayers").on("change" , (evt) => {
    const buldLayerName = $(evt.currentTarget).val();
    console.debug(buldLayerName);

    map.setLayoutProperty("tl_spbd_buld", "visibility", "none");
    map.setLayoutProperty("al_d010", "visibility", "none");
    map.setLayoutProperty("f_fac_building", "visibility", "none");

    map.setLayoutProperty("vw_tb_engy_bld_yy_tl_spbd_buld", "visibility", "none");
    map.setLayoutProperty("vw_tb_engy_bld_yy_al_d010", "visibility", "none");
    map.setLayoutProperty("vw_tb_engy_bld_yy_f_fac_buld", "visibility", "none");
    
    switch (buldLayerName) {
      case 'TL_SPBD_BULD': 
                            map.setLayoutProperty("tl_spbd_buld", "visibility", "visible"); 
                            map.setLayoutProperty("vw_tb_engy_bld_yy_tl_spbd_buld", "visibility", "visible");  
                            break;
      case 'AL_D010':      
                            map.setLayoutProperty("al_d010", "visibility", "visible");        
                            map.setLayoutProperty("vw_tb_engy_bld_yy_al_d010", "visibility", "visible");
                            map.setLayoutProperty("tb_if_kict_pi_sun_lit_mapng_label", "visibility", 'none'); //음영지표는 폴리곤 경계가 달라서 표시하지 않는다.
                            map.setLayoutProperty("tb_if_kict_pi_sun_lit_mapng", "visibility", 'none'); //음영지표는 폴리곤 경계가 달라서 표시하지 않는다.
                            break;
      case 'F_FAC_BULD':   
                            map.setLayoutProperty("f_fac_building", "visibility", "visible"); 
                            map.setLayoutProperty("vw_tb_engy_bld_yy_f_fac_buld", "visibility", "visible");
                            map.setLayoutProperty("tb_if_kict_pi_sun_lit_mapng_label", "visibility", 'none'); //음영지표는 폴리곤 경계가 달라서 표시하지 않는다.
                            map.setLayoutProperty("tb_if_kict_pi_sun_lit_mapng", "visibility", 'none'); //음영지표는 폴리곤 경계가 달라서 표시하지 않는다.
                            break;
    }
  });

      /**
       * 지도 배경 우클릭 리스트 클릭할때
       * */
       $(".btn_otherView li").on("click", (e) => {
          $(".btn_otherView").hide();
          if('TL_SPBD_BULD' != $("#buldLayers").val() ){
            alert('배경건물모드가 [행안부.도로명주소건물] 일경우에만 보실 수 있습니다.');
            return;
          }
          
          if (e.target.dataset.cb === 'bldSunLightSummerView') {
            console.log('일사량_여름');
            gis.search.bldSunLightView('visible','summer');
          } else if(e.target.dataset.cb === 'bldSunLightAnnualView'){
            console.log('일사량_연간');
            gis.search.bldSunLightView('visible','annual');
          } else if(e.target.dataset.cb === 'bldSunLightWinterView'){
            console.log('일사량_겨울');
            gis.search.bldSunLightView('visible','winter');
          } else if(e.target.dataset.cb === 'bldSunLightHide'){
            console.log('일사량_끄기');
            gis.search.bldSunLightView('none');
          } else {
           console.log('우클릭컨텍스트메뉴가 없음');
          }
        });

      /**
       * 현재 건물 기본정보 리스트 클릭할때
       * */
  $(".btn_energyView li").on("click", (e) => {
    $(".btn_energyView").hide();
    if (e.target.dataset.cb === 'bldDetailPopup') {
      //this.onClickBuildInfo(layerMgm);
      //alert('this.onClickBuildInfoNew23(bldInfoData);');
      this.onClickBuildInfoNew23(bldInfoData);
    } else if(e.target.dataset.cb === 'bldBepaReportPopup'){
      this.onClickBldBepaReportPopup(bldInfoData);
    } else {
      this.onClickEnergyUse(layerMgm);
    }
  })

      /**
       * 현재 건물 기본정보 리스트 클릭할때
       * */
  $(".btn_bldView li").on("click", (e) => {
    $(".btn_bldView").hide();
    //this.onClickBuildInfo(layerMgm);
    // this.onClickBuildInfoNew23(bldInfoData);
    
    if (e.target.dataset.cb === 'bldDetailPopup') {
     // this.onClickBuildInfo(layerMgm);
    	this.onClickBuildInfoNew23(bldInfoData);
    } else if(e.target.dataset.cb === 'bldBepaReportPopup'){
      this.onClickBldBepaReportPopup(bldInfoData);
    } 
  })

      /**
       * 현재 건물 기본정보
       * 현재 건물 에너지 소비량 리스트 클릭할때
       * */
  $(".btn-add1 li").on("click", (e) => {
    $(".btn-add1").hide();
    if (e.target.dataset.cb === 'bldDetailPopup') {
     // this.onClickBuildInfo(layerMgm);
    	this.onClickBuildInfoNew23(bldInfoData);
    } else if(e.target.dataset.cb === 'bldBepaReportPopup'){
      this.onClickBldBepaReportPopup(bldInfoData);
    } else {
      selectedModel = 'kict98'
      showSurveyElement();
      map.setFilter("tl_spbd_buld", [
      "!in",
      "bd_mgt_sn",
      "1144010500100700002003775",
      ]);
    }
  })

      /**
       * 현재 건물 기본정보
       * 현재 건물 에너지 소비량
       * kboss 설문 리스트 클릭할때
       * */
  $(".btn-add2 li").on("click", (e) => {
    $(".btn-add2").hide();
    if (e.target.dataset.cb === 'bldDetailPopup') {
     // this.onClickBuildInfo(layerMgm);
    	this.onClickBuildInfoNew23(bldInfoData);
    } else if (e.target.dataset.cb === 'bldEnergyDetailPopup') {
      this.onClickEnergyUse(layerMgm);
    } else if(e.target.dataset.cb === 'bldBepaReportPopup'){
      this.onClickBldBepaReportPopup(bldInfoData);
    } else {
      selectedModel = 'kict99'
      showSurveyElement();
      map.setFilter("tl_spbd_buld", [
      "!in",
      "bd_mgt_sn",
      "1168010600110240003000001",
      ]);

      
      map.setFilter(all4LayerControls.getCurrentBackgroundBuldLayerName(), [ "!in", "mgm_bld_pk","11680-100222189"  ]);
    //   map.setFilter("vw_tb_engy_bld_yy_tl_spbd_buld", [
    //     "!in",
    //     "mgm_bld_pk",
    //     "11680-100222189",
    //   ]);
    }
  })

      /**
       * 설문 조사 보여주는 기능
       * */
    function showSurveyElement() {
      clearInterval(sensorFlicker);
      const floorLevel = code.kictFloor[selectedModel];
      showSatisfaction("6-1.", floorLevel.toString());
      while (btnFloor.hasChildNodes()) {
        btnFloor.removeChild(btnFloor.firstChild);
      }
      for (let i = 1; i <= floorLevel; i++) {
        map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "visible");
        map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 1);

        const button = document.createElement('button');
        const text = document.createTextNode(`${i}층`);
        button.setAttribute('data-value', `F0${i}`);
        button.appendChild(text);
        button.addEventListener('click', btnFloorListener);
        if (i === floorLevel) {
          button.classList.add('on');
          sensorFlicker = setInterval(() => {
           if (typeof map.getPaintProperty === 'function') { //mapbox 기반 3D맵
            const opacity_ = map.getPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity");
            if (opacity_ == 0.6) {
                map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 0.2);
            } else {
                map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 0.6);
            }
           } else {
        	   clearInterval(sensorFlicker);
           }
          }, 400);
        }
        btnFloor.prepend(button);
      }

      $(".btn-floor").show();
      $("#btn-floor-close").show();
      $("#select-container").show();
    }

  /**
   * mapbox 메뉴 3D건물 클릭기능
   * */
  const btnFloor = document.querySelector('.btn-floor');
  $(".btn_3dview li").on("click", function (e) {
    $(".btn_3dview").hide();
    const modelName = $(this).attr("value");

    if (e.target.dataset.cb === 'bld3d') {
      if (modelName) {
      const options = "top=10, left=10, width=900, height=700, status=no, menubar=no, toolbar=no, resizable=no, location=no, scrollbars=no";
      window.open(
//        "http://checkup.building-energy.org:12230/popup/?modelName=" + modelName,
        `https://geo3d.building-energy.org/popup/?modelName=${modelName}`,
        "popup",
        options,
      );
      } else {
        console.log("null");
      }
    } else {
      if (selectedModel !== undefined && selectedModel.length > 0) {
        const preLevel = code.kictFloor[selectedModel];
        for (let i = 1; i <= preLevel; i++) {
          map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
        }
        const selectedObject = scene.getObjectByName("survey");
        selectedObject.parent.remove(selectedObject);
      }

      scene.children[1].children.forEach((glb) => {
        if (modelName === "100240") {
          if (glb.name === "100260") {
            glb.visible = false;
          } else {
            glb.visible = true;
          }
        } else if (glb.name === modelName) {
            glb.visible = false;
          } else {
            glb.visible = true;
          }
      })

      selectedModel = `kict${modelName.slice(3, 5)}`;
      showSurveyElement();
    }
  });

  /**
   * 만족도 조사 기능
   * */
  const btnSelect = document.getElementById("btn-dropdown");
  const navSelect = document.getElementById("nav-dropdown");

  btnSelect.addEventListener('click', () => {
    if (!btnSelect.classList.contains("is-open")) {
      btnSelect.classList.add("is-open");
      navSelect.classList.add("is-open");
    } else {
      btnSelect.classList.remove("is-open");
      navSelect.classList.remove("is-open");
    }
  });

  const navList = navSelect.querySelector("ul");
  navList.addEventListener('click', (e) => {
    navList.querySelectorAll('li').forEach(e => {
      e.classList.remove("on");
    })
    e.target.classList.add("on");
    const selectedfloor = document.querySelector('.btn-floor button.on');
    const selectedObject = scene.getObjectByName("survey");
    selectedObject.parent.remove(selectedObject);
    showSatisfaction(e.target.dataset.value, selectedfloor.dataset.value.slice(2))
    btnSelect.classList.remove("is-open");
    navSelect.classList.remove("is-open");
  });

  /**
   * 건물 메뉴창 클릭 기능
   * * */
  let btnFloorListener = (e) => {
    clearInterval(sensorFlicker);
    btnFloor.querySelectorAll('button').forEach(e => {
      e.classList.remove("on");
    })
    e.target.classList.add("on");

    navList.querySelectorAll('li').forEach(e => {
      e.classList.remove("on");
    })
    navList.querySelector('li:nth-child(13)').classList.add("on");
    const selectedObject = scene.getObjectByName("survey");
    selectedObject.parent.remove(selectedObject);

    const index = e.target.dataset.value.slice(2, 3);
    const floorLevel = code.kictFloor[selectedModel];
    showSatisfaction("6-1.", index);
    for (let i = 1; i <= floorLevel; i++) {
      map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "visible");
      map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 1);
    }
    for (let i = floorLevel; i > index; i--) {
      map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
    }

    sensorFlicker = setInterval(() => {
        if (typeof map.getPaintProperty === 'function') { //mapbox 기반 3D맵
		    const opacity_ = map.getPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity");
		    if (opacity_ == 0.6) {
		        map.setPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity", 0.2);
		    } else {
		        map.setPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity", 0.6);
		    }
        } else {
            clearInterval(sensorFlicker);
        }
    }, 400);
  }

  /**
   * 층별 취소 버튼
   * * */
  const btnFloorClose = document.querySelector('#btn-floor-close');
  btnFloorClose.addEventListener('click', (e) => {
    $(".btn-floor").hide();
    $("#btn-floor-close").hide();
    $("#select-container").hide();
    clearInterval(sensorFlicker);

    const preLevel = code.kictFloor[selectedModel];
    for (let i = 1; i <= preLevel; i++) {
      map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
    }
    const selectedObject = scene.getObjectByName("survey");
    selectedObject.parent.remove(selectedObject);

    scene.children[1].children.forEach((glb) => {
        let glbName = `100${selectedModel.slice(4, 6)}0`;
        if (glbName === "100240") glbName = "100260";
        if (glb.name === glbName) {
          glb.visible = true;
        }
    })

    selectedModel = '';
    map.setFilter("tl_spbd_buld", [
      "!in",
      "bd_mgt_sn",
      "4128710400123110001004201",
      "4128710400123110001012494",
      "4128710400123110001012497",
      "4128710400123110001012222",
      "4128710400123110001012498",
      "4128710400123110001012223",
      "4128710400123110001012221",
      "4128710400123110001012492",
      "4128710400123110001012499",
      "4128710400123110001012491",
      "4128710400123110001012493",
      "4128710400123110000012292",
      "4128710400123110000012298",
      "4128710400123110000012293",
      "4128710400123110000012295",
      "4128710400123110000004200",
      "4128710400123110000012297",
      "4128710400123110000012504",
      "4128710400123110000012294",
    ]);
    map.setFilter(all4LayerControls.getCurrentBackgroundBuldLayerName(), ["==", "use_yy", "2017"]);
    // map.setFilter("vw_tb_engy_bld_yy_tl_spbd_buld", ["==", "use_yy", "2017"]);
  })

    /**
   * 설문 클릭시 설문조사 보여주는 기능
   * * */
  function showSatisfaction(statisFactionNumber = "6-1.", floor) {
    $.ajax({
      url: `data/kictsurvey/${selectedModel}.json`,
      async: true,
      type: "GET",
      success: function (data) {
        const filterData = data.filter(e => e.floor === floor);
        const group = new THREE.Group();

        filterData.forEach((v) => {
          const statis = parseInt(v[statisFactionNumber]);
          const color = code.satisfactionColor[statis.toString()];
          const lon = +v["만족도 조사 위치 경도"];
          const lat = +v["만족도 조사 위치 위도"];
          group.add(threeLayer.addSpacePeople([lon, lat], v.floor, color));
        });
        group.position.y = 3 * floor - 1.5;
        group.name = "survey";
        scene.add(group);
      },
      error: function () {
        console.log("error");
      },
    });
    }
  },
  methods: {

	async onClickBuildInfoNew23(bldInfoData) {
     // gis.search.moveToBldGeom(map, bldInfoData);

      // 전역변수 true
      //this.buildClickTempInfo = true;

      this.mapngGrade = '';
      this.layerKorName = '';

      // this.mapngGrade = bldInfoData.mapngGrade
      // this.layerKorName = bldInfoData.layerKorName
      // if (typeof this.mapngGrade === "undefined" || this.mapngGrade === "") {
      //   this.mapngGrade = "-"
      //   this.layerKorName = "등급판정불가"
      // }
      // this.modalBuildInfo = !this.modalBuildInfo;

      // modal 타이틀에 건물정보, 주소를 입력하기 위해 db 조회
      const res = await this.$store.dispatch('main/FETCH_BUILD_TOTAL_TITLE_DATA', {
        mgmBldPk: bldInfoData.mgmBldPk,
      });
      if (res) {
        this.modalBuildTotal = !this.modalBuildTotal;
        this.buildTotalInfo.bldNm = res.bldnm != null ? res.bldnm : '-';
        this.buildTotalInfo.bldAddr = res.roadplataddr != null ? res.roadplataddr : '-';
        this.modalBuildInfo = !this.modalBuildInfo;

        this.mgmBldPk = bldInfoData.mgmBldPk;
        if (this.bldInfoData.mgmBldPk !== bldInfoData.mgmBldPk) {
          this.bldInfoData = bldInfoData;
        }

        this.$store
          .dispatch('main/FETCH_BUILD_MAPNG_GRADE_DATA', {
            dataCode: 'buildMapngGrade',
            mgmBldPk: bldInfoData.mgmBldPk,
            layerName: bldInfoData.layerId,
          }).then(() => {
            const buildMapngGradeData = this.$store.state.main.buildMapngGrade;
            if (buildMapngGradeData && buildMapngGradeData.length > 0) {
              // 데이터가 비동기로 불러와졌을 때 표시
              this.mapngGrade = buildMapngGradeData[0].mapng_grade;
              this.mapngGradeDesc = this.$store.state.main.buildMapngGrade[0].grade_cls3_desc;
              this.layerKorName = buildMapngGradeData[0].origin_name;
            } else {
              // 데이터가 없는 경우 기본값 표시
              this.mapngGrade = '-';
              this.mapngGradeDesc = '-';
              this.layerKorName = '등급판정불가';
            }
          }).catch(() => {
            // 요청 실패 시 기본값 표시
            this.mapngGrade = '-';
            this.layerKorName = '등급판정불가';
          });
/*
        // 선택한 건물 정보 저장
       await this.$store.dispatch('main/FETCH_BUILD_READ_HISTORY_DATA', {
          mgmBldPk: bldInfoData.mgmBldPk,
        });

        // 선택한 건물 내 건물로 등록한 내역 조회
        this.isStarred = await this.$store.dispatch('main/FETCH_BUILD_GET_BOOKMARK_DATA', {
          mgmBldPk: bldInfoData.mgmBldPk,
        });
*/
        // 클릭시 기존 sotre 초기화
        this.$store.state.main.selectMenuComp = null;
      }
    },

    mover() {
      this.helpBoxShow = true;
    },
    mout() {
      this.helpBoxShow = false;
    },

    onClickBuildInfo(mgmBldPk) {
      // 전역변수 true
      this.buildClickTempInfo = true
      //this.mgmBldPkArr.push(mgmBldPk)

      this.modalBuildInfo = !this.modalBuildInfo
      this.mgmBldPk = mgmBldPk
    },
    // onClickMapArea(title, mapType, sigunguId, dongId) {
    //   this.modalMapAreaInfo = !this.modalMapAreaInfo
    //   this.areaTitle = `▶ ${title} 통계 요약 (건물평균)`
    //   this.sigunguId = sigunguId
    //   this.dongId = dongId
    //   this.mapType = mapType
    //   // this.$store.dispatch("main/FETCH_MAP_AREA_DATA", this.mapId);
    // },
    onClickEnergyUse(mgmBldPk) {
      // 전역변수 true
      this.buildClickTempInfo = true
      // this.mgmBldPkArr.push(mgmBldPk)

      this.modalEnergyInfo = !this.modalEnergyInfo
      this.mgmBldPk = mgmBldPk
    },
    // onClickLasso(mgmBldPkLasso) {
    //   this.modalLassoInfo = !this.modalLassoInfo
    //   this.mgmBldPkLasso = mgmBldPkLasso
    // },
    // onClickCommProc() {
    //   this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
    //   this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
    //   this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
    //   this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
    //   this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

    //   if (this.mainSelected.MAIN_PURPS_CD) {
    //     this.mainSelected.MAIN_PURPS_CD.forEach(item => {
    //       const temp = {};
    //       temp.prupsCd = item.cdId;

    //       if (!this.purpsArr.includes(item.cdId)) {
    //         this.purpsArr.push(item.cdId);
    //       }
    //       this.commonEnergy.prupsCd = this.purpsArr
    //     });
    //   }
    //   console.log(JSON.stringify(this.commonEnergy))
    // },
    
    changeBuldLayerVal(event) {
			const buldLayersItem = document.getElementsByClassName('buldLayersItem');
			const buldLayerTitle = document.getElementsByClassName('buldLayerTitle');
			const buldLayerExp = document.getElementsByClassName('buldLayerExp');

			buldLayersItem.forEach((e) => { e.style.background = '#f0f0f0'; });
			buldLayerTitle.forEach((e) => { e.style.color = '#000'; });
			buldLayerExp.forEach((e) => { e.style.color = '#a0a0a0'; });

			let selectedElementId = '';

			if (event.target.classList[0] === 'buldLayerItemWrap') return;

			if (event.target.id === '') {
				selectedElementId = event.target.parentNode.id;
			} else {
				selectedElementId = event.target.id;
			}

			const eventGen = new Event('change');
			const parentNode = document.getElementById(selectedElementId);
			document.getElementById('buldLayers').value = selectedElementId;
			document.getElementById('buldLayers').dispatchEvent(eventGen);   //  $("#buldLayers").on("change" , (evt) => { 로  이벤트 넘긴다.

			document.getElementById(selectedElementId).style.background = '#4441d4';
			parentNode.getElementsByClassName('buldLayerTitle')[0].style.color = '#fff';
			parentNode.getElementsByClassName('buldLayerExp')[0].style.color = '#fff';
		},
		buldLayerBtnClk(event) {
			this.buldLayerFlag = !this.buldLayerFlag;
		},

    async onClickBldBepaReportPopup(data){
      
         // BEPA 대상 건물인지 조회
      const res = await this.$store.dispatch('main/FETCH_PDF_BEPA_EXIST', {
        mgmBldPk: data.mgmBldPk,
      });
      if (res) {
        this.modalPdfBEPA = !this.modalPdfBEPA;
        this.mgmBldPk = data.mgmBldPk;
      } else {
        alert('BEPA 리포트가 없습니다.');
      }
    },
  }

}

</script>

<style lang="scss">
.container {
  width: 100% !important;
  margin: 0;
  padding: 0;
  max-width: none !important;
}

.menuHelp {
  position: relative;
  top: -3px;
  margin-left: 5px;
  margin-right: 12px;
  cursor: pointer;

  &Box{
    border: 2px solid #1560ad;
    width: 525px;
    padding: 10px;
    display: inline-block;
    position: absolute;
    top: 45px;
    right: 30px;
    z-index: 99;
    background: white;
    font-size: 14px;
    font-weight: normal;
    border-radius: 10px;
    //font-family: 'NSK', 맑은고딕, 'Malgun Gothic', 돋움, Dotum, sans-serif;
  }
}

.left-div {
  width: 65.8%;
}
.modal-xl {
  max-width: 70% !important;
}
.gradeDiv {
  display: flow-root;
}

</style>
