/**
 *  layerControls.js
 *
 *  geoserver 에 WMTS 타일을 올려서 해당 URL을 가져와서 layer에 추가한다
 *  {@link https://docs.mapbox.com/mapbox-gl-js/api/map/#map#addlayer}
 * */

import { image } from "vee-validate/dist/rules";
import code from "../common/code";

/**
 *  map에 layer 추가
 *
 *  geoserver 에 타일을 올려놓고 url 을 넣어준다
 *  {@link http://checkup.building-energy.org:12230/geoserver/gwc/demo}
 *
 *  타일 형식은 WMTS (웹 맵 타일 서비스)
 *  KICT:tl_spbd_buld, KICT:vw_tb_engy_bld_yy_tl_spbd_buld, KICT:tb_engy_2017_11_eb
 * */
function addInitLayer() {

  //배경건물1:행안부.도로명주소건물 추가
  map.addLayer({
    id: "tl_spbd_buld",
    type: "fill-extrusion",
    minzoom: 13,
    maxzoom: 20,
    source: {
      type: "vector",
      tiles: [
//        `https://${code.geoserverIP}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=KICT:tl_spbd_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=edoc3d:tl_spbd_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      //bounds: [126.36381402141426, 36.89148678883987, 127.82562229923616,38.17522139418934],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "tl_spbd_buld",
    paint: {
      "fill-extrusion-color": "#cdcdcd",
      "fill-extrusion-height": ["*"
                                  , ["case" 
                                        , ["<=" 
                                              ,["to-number", ["get", "gro_flo_co"], 0.5]    // to-number 의 결과 NaN 또는 null 이면  
                                              , 0.5                                         // 0.5 층이라고 default 세팅 반환
                                          ]                                                 // 만약 위에서 반환된값이 0.5보다 작거나 같으면 (0포함)
                                        , 0.5                                              // 0.5층이라고 강제로 층정보 세팅
                                        , ["to-number", ["get", "gro_flo_co"]]              //그게 아니라 정상적인 값이라면 해당 값 사용  
                                    ]
                                  , 3                                                       //반환된 값을 사용하여 * 3m 해서 높이로 사용함
                                ],
      // "fill-extrusion-height": ["*", ["to-number", ["get", "gro_flo_co"], 0], 3],  //건물층수*3m
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "gro_flo_co"], 0], 3], 1], //건물층수*3m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "visible" },
  });
  map.setFilter("tl_spbd_buld", [
    "!in",
    "bd_mgt_sn",
    "4128710400123110001004201",
    "4128710400123110001012494",
    "4128710400123110001012497",
    "4128710400123110001012222",
    "4128710400123110001012498",
    "4128710400123110001012223",
    "4128710400123110001012221",
    "4128710400123110001012492",
    "4128710400123110001012499",
    "4128710400123110001012491",
    "4128710400123110001012493",
    "4128710400123110000012292",
    "4128710400123110000012298",
    "4128710400123110000012293",
    "4128710400123110000012295",
    "4128710400123110000004200",
    "4128710400123110000012297",
    "4128710400123110000012504",
    "4128710400123110000012294",
  ]);

  //배경건물2:국토부.GIS건물정보 추가
  map.addLayer({
    id: "al_d010",
    type: "fill-extrusion",
    minzoom: 13,
    maxzoom: 20,
    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=edoc3d:al_d010&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "al_d010",
    paint: {
      "fill-extrusion-color": "#cdcdcd",
      "fill-extrusion-height": ["*"
                                  , ["case" 
                                        , ["<=" 
                                              ,["to-number", ["get", "a16"], 0.5]    // to-number 의 결과 NaN 또는 null 이면  
                                              , 0.5                                                      // 0.5m 이라고 default 세팅 반환
                                          ]                                                              // 만약 위에서 반환된값이 0.5보다 작거나 같으면 (0포함)
                                        , 0.5                                                            // 0.5m 이라고 강제로 높이정보 세팅
                                        , ["to-number", ["get", "a16"]]              //그게 아니라 정상적인 값이라면 해당 값 사용  
                                    ]
                                  , 1                                                                    //반환된 값을 사용하여 * 1m 해서 높이로 사용함
                                ],
      // "fill-extrusion-height":  ["*", ["to-number", ["get", "a16"], 0], 1], //건물높이*1m  
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "a16"], 0], 1], 1], //건물높이*1m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });

  //배경건물3:국토부.건물통합정보마스터 추가
  map.addLayer({
    id: "f_fac_building",
    type: "fill-extrusion",
    minzoom: 13,
    maxzoom: 20,
    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=edoc3d:f_fac_building&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "f_fac_building",
    paint: {
      "fill-extrusion-color": "#cdcdcd",
      "fill-extrusion-height": ["*"
                                  , ["case" 
                                        , ["<=" 
                                              ,["to-number", ["get", "grnd_flr"], 0.5]    // to-number 의 결과 NaN 또는 null 이면  
                                              , 0.5                                         // 0.5 층이라고 default 세팅 반환
                                          ]                                                 // 만약 위에서 반환된값이 0.5보다 작거나 같으면 (0포함)
                                        , 0.5                                              // 0.5층이라고 강제로 층정보 세팅
                                        , ["to-number", ["get", "grnd_flr"]]              //그게 아니라 정상적인 값이라면 해당 값 사용  
                                    ]
                                  , 3                                                       //반환된 값을 사용하여 * 3m 해서 높이로 사용함
                                ],
      // "fill-extrusion-height": ["*", ["to-number", ["get", "grnd_flr"], 0], 3],//건물층수*3m 
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "grnd_flr"], 0], 3], 1],//건물층수*3m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });


//에너지사용량 조회를 위한 default 필터 추가 
    var filters = ['all'];
    
    if(  gis.search.globalEnergyFilterParam != null ){
        var param = gis.search.globalEnergyFilterParam ;
        if(param.useYyCd != null && param.useYyCd != '' && typeof param.useYyCd == 'string'){
            filters.push(['==', 'use_yy', param.useYyCd]);
        }else{
            filters.push(['==', 'use_yy', '2022']);
        }
        
        if(param.prupsCd !== null && typeof param.prupsCd == 'object' && Array.isArray(param.prupsCd) && param.prupsCd.length > 0){
            filters.push(['in', 'main_purps_cd', ...param.prupsCd]);
        }else{
            filters.push(['==', 'main_purps_cd', 'none']);
        }
    
    }

  
  //에너지사용량1:행안부.도로명주소건물.에너지사용량(년도별) 추가
  map.addLayer({
    id: "vw_tb_engy_bld_yy_tl_spbd_buld",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
//        `https://${code.geoserverIP}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&cql_filter=use_yy='2019'&LAYER=KICT:vw_tb_engy_bld_yy_tl_spbd_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&cql_filter=use_yy='2022'&LAYER=edoc3d:vw_tb_engy_bld_yy_tl_spbd_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      //bounds: [126.76718294907049, 37.43008165268006, 127.2229276778434,37.69922823006717],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "vw_tb_engy_bld_yy_tl_spbd_buld",
    filter: filters,
    paint: {
      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*"
                                  , ["case" 
                                        , ["<=" 
                                              ,["to-number", ["get", "gro_flo_co_tl_spbd_buld"], 0.5]    // to-number 의 결과 NaN 또는 null 이면   0.5 층이라고 반환환
                                              , 1                                                        // 만약 위에서 반환된값이 1(층)보다 작거나 같으면 (0포함)
                                          ]                                                          
                                        , 1                                                              // 1층이라고 강제로 층정보 세팅
                                        , ["to-number", ["get", "gro_flo_co_tl_spbd_buld"]]              //그게 아니라 정상적인 값이라면 해당 값 사용  
                                    ]
                                  , 3                                                                    //반환된 값을 사용하여 * 3m 해서 높이로 사용함
                                ],
      // "fill-extrusion-height": ["*", ["to-number", ["get", "gro_flo_co_tl_spbd_buld"], 0], 3],//건물층수*3m 
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "gro_flo_co_tl_spbd_buld"], 0], 3], 1],//건물층수*3m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "visible" },
  });

  //에너지사용량2:국토부.GIS건물정보.에너지사용량(년도별) 추가
    map.addLayer({
    id: "vw_tb_engy_bld_yy_al_d010",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&cql_filter=use_yy='2022'&LAYER=edoc3d:vw_tb_engy_bld_yy_al_d010&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      //bounds: [126.76718294907049, 37.43008165268006, 127.2229276778434,37.69922823006717],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "vw_tb_engy_bld_yy_al_d010",
    filter: filters,
    paint: {
      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*"
                                  , ["case" 
                                        , ["<=" 
                                              ,["to-number", ["get", "a16_al_d010"], 0.5]    // to-number 의 결과 NaN 또는 null 이면  
                                              , 0.5                                                      // 0.5m 이라고 default 세팅 반환
                                          ]                                                              // 만약 위에서 반환된값이 0.5보다 작거나 같으면 (0포함)
                                        , 0.5                                                            // 0.5m 이라고 강제로 높이정보 세팅
                                        , ["to-number", ["get", "a16_al_d010"]]              //그게 아니라 정상적인 값이라면 해당 값 사용  
                                    ]
                                  , 1                                                                    //반환된 값을 사용하여 * 1m 해서 높이로 사용함
                                ],
      // "fill-extrusion-height":  ["*", ["to-number", ["get", "a16_al_d010"], 0], 1], //건물높이*1m  
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "a16_al_d010"], 0], 1], 1], //건물높이*1m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });
  
  //에너지사용량3:국토부.건물통합정보마스터.에너지사용량(년도별) 추가
  map.addLayer({
    id: "vw_tb_engy_bld_yy_f_fac_buld",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&cql_filter=use_yy='2022'&LAYER=edoc3d:vw_tb_engy_bld_yy_f_fac_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      //bounds: [126.76718294907049, 37.43008165268006, 127.2229276778434,37.69922823006717],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "vw_tb_engy_bld_yy_f_fac_buld",
    filter: filters,
    paint: {
      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*"
                                    , ["case" 
                                          , ["<=" 
                                                ,["to-number", ["get", "grnd_flr_f_fac_buld"], 0.5]    // to-number 의 결과 NaN 또는 null 이면  
                                                , 0.5                                                  // 0.5 층이라고 default 세팅 반환
                                            ]                                                          // 만약 위에서 반환된값이 0.5보다 작거나 같으면 (0포함)
                                          , 0.5                                                        // 0.5층이라고 강제로 층정보 세팅
                                          , ["to-number", ["get", "grnd_flr_f_fac_buld"]]              // 그게 아니라 정상적인 값이라면 해당 값 사용  
                                      ]
                                    , 3                                                                // 반환된 값을 사용하여 * 3m 해서 높이로 사용함
                                  ],
      // "fill-extrusion-height": ["*", ["to-number", ["get", "grnd_flr_f_fac_buld"], 0], 3],//건물층수*3m 
      // "fill-extrusion-height": ["-", ["*", ["to-number", ["get", "grnd_flr_f_fac_buld"], 0], 3], 1],//건물층수*3m  -1m 옥상 높이가 겹쳐서 빗살무늬가 나타나는 현상으로 높이를 살짝 내린다.
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });





  map.addLayer({
    id: "tb_engy_2017_11_eb",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=edoc3d:tb_engy_2017_11_eb&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      //bounds: [126.36381402141426, 36.89148678883987, 127.82562229923616,38.17522139418934],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "tb_engy_2017_11_eb",
    paint: {

      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "use_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*", ["to-number", ["get", "flr_no"]], 3],
      "fill-extrusion-base": ["-",["*", ["to-number", ["get", "flr_no"]], 3],3,],
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });

  // map.loadImage('./images/search_m1.1.png', function (error, iconImage) {
  //   if (error) throw error;
  //   map.addImage('bepaIcon1', iconImage); 
  //   map.loadImage('./images/search_m1.2.png', function (error, iconImage) {
  //     if (error) throw error;
  //     map.addImage('bepaIcon2', iconImage);
  //     map.loadImage('./images/search_m1.3.png', function (error, iconImage) {
  //       if (error) throw error;
  //       map.addImage('bepaIcon3', iconImage);
        
  //       map.addSource(  'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson'
  //                     , {  type: 'geojson'
  //                         , data:'http://check.building-energy.org:12230/geoserver/edoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=edoc%3Avw_tb_diag_bld_seoul_tl_spbd_buld&outputFormat=application%2Fjson'
  //                     });

  //       map.addLayer({ id: 'BEPA_diagBldSeoul_TL_SPBD_BULD'
  //                     ,type: 'symbol'//,type: 'circle'
  //                     ,source: 'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson'
  //                     ,layout: {
  //                       'icon-image': [
  //                         'match',
  //                         ['get', 'zoom'],
  //                         15, 'bepaIcon1',
  //                         10, 'bepaIcon2',
  //                         'bepaIcon3' // 디폴트 값
  //                       ],
  //                       'icon-allow-overlap': true,
  //                       'icon-ignore-placement': true
  //                     }
  //                     // ,paint: { 'circle-radius': 5
  //                     //           ,'circle-color': 'rgba(55,148,179,1)'
  //                     //           ,'circle-stroke-width': 2
  //                     //           ,'circle-stroke-color': '#ffffff'
  //                     //         }
  //       });
            

  //     });
  //   });
  // });
  

  map.addSource(  'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson'
                , {  type: 'geojson'
                   , data:`${code.geoserverIP}/edoc3d/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=edoc3d:vw_tb_diag_bld_seoul_tl_spbd_buld&outputFormat=application%2Fjson`
               });

  // map.addLayer({ id: 'BEPA_diagBldSeoul_TL_SPBD_BULD'
  //               ,type: 'symbol'//,type: 'circle'
  //               ,source: 'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson'
  //               ,minzoom : 8
  //               ,layout: {
  //                 'icon-image': 'bepaIcon1',
  //                 'icon-allow-overlap': true,
  //                 'icon-ignore-placement': true
  //               } 
  // });
  // 8~12 레벨: bepaIcon3
map.addLayer({
  id: 'BEPA_diagBldSeoul_TL_SPBD_BULD-bepaIcon3',
  type: 'symbol',
  source: 'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson',
  minzoom: 8,
  maxzoom: 10,
  layout: {
      'icon-size' : 0.05,
      'icon-image': 'bepaIcon3',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
  }
});

// 12~16 레벨: bepaIcon2
map.addLayer({
  id: 'BEPA_diagBldSeoul_TL_SPBD_BULD-bepaIcon2',
  type: 'symbol',
  source: 'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson',
  minzoom: 10,
  maxzoom: 16,
  layout: {
      'icon-size' : 0.1,
      'icon-image': 'bepaIcon3',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
  }
});

// 16~20 레벨: bepaIcon1
map.addLayer({
  id: 'BEPA_diagBldSeoul_TL_SPBD_BULD-bepaIcon1',
  type: 'symbol',
  source: 'BEPA_diagBldSeoul_TL_SPBD_BULD_geojson',
  minzoom: 16,
  // maxzoom: 20,
  layout: {
      'icon-size' : 0.2,
      'icon-image': 'bepaIcon3',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
  }
});

     
  map.addLayer({
    id: "tb_if_kict_pi_sun_lit_mapng",
    type: "fill-extrusion",
    minzoom: 6,
    source: {
      type: "vector",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=edoc3d:tb_if_kict_pi_sun_lit_mapng&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
    },
    "source-layer": "tb_if_kict_pi_sun_lit_mapng",
     filter: [  'all',
                ['==', 'seasons', 'winter'] //winter , summer, annual
            ],
    "paint": {
      "fill-extrusion-color": [
          "case",
          [">=", ["to-number", ["get", "sum_pi_sl_norm"]], 0.8],"#4D4730",
          [">=", ["to-number", ["get", "sum_pi_sl_norm"]], 0.6],"#4B6858",
          [">=", ["to-number", ["get", "sum_pi_sl_norm"]], 0.4],"#498C8A",
          [">=", ["to-number", ["get", "sum_pi_sl_norm"]], 0.2],"#46ACC2",
          ["<", ["to-number", ["get", "sum_pi_sl_norm"]], 0.2],"#42F2F7",
          "#CDCDCD"
      ],
      "fill-extrusion-height": ["*", ["to-number", ["get", "gro_flo_co"]], 3],
      "fill-extrusion-base": ["*", ["to-number", ["get", "gro_flo_co"]], 3],
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });



  map.addSource('tb_if_kict_pi_sun_lit_mapng_labels_geojson', {
    'type': 'geojson',
    'data':  `${code.geoserverIP}/edoc3d/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=edoc3d:vw_tb_if_kict_pi_sun_lit_mapng_center&outputFormat=application%2Fjson`,
  });

   
  map.addLayer({
      'id': 'tb_if_kict_pi_sun_lit_mapng_label',
      'type': 'symbol',
      'source': 'tb_if_kict_pi_sun_lit_mapng_labels_geojson',
      'minzoom': 16,
      'layout': {
          'text-field': ['get', 'sum_pi_sl_norm'], // 라벨로 표시될 필드명을 지정합니다.
          'text-font': ['Open Sans Regular'],
          'text-size': 14,
          'text-anchor': 'top',
          'visibility': "none"
      },
      'paint': {
          'text-color': '#000000'
      },
      'symbol-placement': 'point', // 라벨을 포인트 형식으로 표시합니다.
      'symbol-spacing': 50 , // 라벨 간격 조정 (선택 사항)
  });








  /**
   *  gis/data/kictLayer 폴더안에 json 데이터 layer 추가
   * */
  Object.keys(code.kictFloor).forEach((key) => {
    const totalFloor = code.kictFloor[key];
    for (let i = 1; i <= totalFloor; i++) {
      map.addSource(`${key}_${i}`, {
        type: "geojson",
        data: `data/kictLayer/${key}.json`,
      });
      map.addLayer({
        id: `${key}_${i}`,
        type: "fill-extrusion",
        source: `${key}_${i}`,
        minzoom: 6,
        layout: { visibility: "none" },
        paint: {
          "fill-extrusion-color": "#cdcdcd",
          "fill-extrusion-height": i * 3,
          "fill-extrusion-base": i * 3 - 3,
          "fill-extrusion-opacity": 1,
        },
      });
    }
  })
}

function addRasterLayer() {
  map.addLayer({
    id: "ilsan_raster",
    type: "raster",
    source: {
      type: "raster",
      tiles: [
        `${code.geoserverIP}/gwc/service/wmts?layer=edoc3d:ilsan_raster_3cm&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}`,
      ],
      bounds: [126.73620857193194, 37.66705285988249, 126.74368368588439,37.67424908708122], // raster 이미지 범위로 제한
      //bounds: [122.71, 28.6, 134.28, 40.27], //EPSG:5179의 국내 WGS84좌표범위로 한정
      minzoom: 13,
      maxzoom: 21,
      tileSize: 512,
    },
  });
}
function addImageIcon() {
  // 이미지 로드
  // let imageUrl2 = require('./images/search_m1.1.png');
  // let imageUrl3 = require('./images/search_m1.2.png');
  // let imageUrl4 = require('./images/search_m1.3.png');

  // 로드된 이미지로 아이콘 추가
  map.loadImage(`${publishingUrl}/gis/js/all4/images/search_m1.1.png`, function (error, iconImage1) {
    if (error) {
      console.log(error);
    }
    map.addImage('bepaIcon1', iconImage1, {width:24, height:24});
  });
  map.loadImage(`${publishingUrl}/gis/js/all4/images/search_m1.2.png`, function (error, iconImage2) {
    if (error) {
      console.log(error);
    }
    map.addImage('bepaIcon2', iconImage2, {width:18, height:18});
  });
  map.loadImage(`${publishingUrl}/gis/js/all4/images/search_m1.3.png`, function (error, iconImage3) {
    if (error) {
      console.log(error);
    }
    map.addImage('bepaIcon3', iconImage3, {width:12, height:12});
  });
 
}

function getCurrentBuldLayerName() {
  switch ($("#buldLayers").val()) {
    case 'TL_SPBD_BULD':  return "vw_tb_engy_bld_yy_tl_spbd_buld";
    case 'AL_D010':       return "vw_tb_engy_bld_yy_al_d010";
    case 'F_FAC_BULD':    return "vw_tb_engy_bld_yy_f_fac_buld";
  }
}

const exports = {
  init() {
    addImageIcon();
    addInitLayer();
  },
  addRaster() {
    addRasterLayer();
  },
  getCurrentBackgroundBuldLayerName(){
    return getCurrentBuldLayerName();
  },
};

export default exports;
