<template>
  <div>
    <!-- <nav-gnb /> -->
    <!-- 2D 3D 지도 -->
    <service-button :current="'building-service-3d'" class="position-absolute mt-1 mr-5 service-button" />

    <div class="address-search">
      <address-search-component></address-search-component>
    </div>

    <!-- 검색  -->
    <div v-show="false" class="input-search">
      <input-search-component :input-placeholder="inputPlaceholder" />
    </div>

    <!--  에너지 소비랑  -->
    <div class="select-use">
      <select-use-component />
    </div>
    <!--  주용도 검색  -->
    <div class="main-purps">
      <main-purps-component />
    </div>
    <!--지도(3D) 표현 -->
    <GisMap3DComponent />
  </div>
</template>

<script>
import ServiceButton from '@/layouts/button/ServiceButton.vue';
import InputSearchComponent from '@/views/building/InputSearchComponent';
import SelectUseComponent from '@/views/building/SelectUseComponent';
import MainPurpsComponent from '@/views/building/MainPurpsComponent';
import GisMap3DComponent from '@/views/building/GisMap3DComponent.vue';
import { mapGetters } from 'vuex';
import NavGnb from '@/layouts/components/NavGnb.vue';
import AddressSearchComponent from '~/building/AddressSearchComponent.vue';

export default {
  components: {
    AddressSearchComponent,
    NavGnb,
    ServiceButton,
    InputSearchComponent,
    SelectUseComponent,
    MainPurpsComponent,
    GisMap3DComponent,
  },
  props: {},
  data() {
    return {
      inputPlaceholder: '동단위로 검색해주세요',
    };
  },
  computed: {
    ...mapGetters({
      engyUseChange: 'main/getEngyUseChange',
    }),
  },

  methods: {},
};
</script>
<style scoped>
.service-button {
  top: 60px;
  left: 35%;
  transform: translate(-50%);
  z-index: 10;
}

.address-search {
  position: absolute;
  z-index: 201;
  left: 45%;
  width: 450px;
  padding: 10px;
  top: 66px;
}

.input-search {
  position: absolute;
  z-index: 202;
  background: white;
  left: 1070px;
  top: 250px;
  width: 380px;
  height: 120px;
  padding: 10px;
  border: thin solid #cac9d0;
  border-radius: 10px;
}

.select-use {
  position: absolute;
  z-index: 201;
  background: white;
  left: 30px;
  width: 250px;
  padding: 10px;
  top: 82px;
  border-radius: 5px;
  border: solid 1px #b6b6b6;
}

.main-purps {
  position: absolute;
  z-index: 200;
  background: white;
  left: 30px;
  width: 250px;
  top: 415px;
  border-radius: 5px;
  border: solid 1px #b6b6b6;
}
</style>
